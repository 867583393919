@keyframes rainbow {
    0% { color: red; }
    14% { color: orange; }
    28% { color: yellow; }
    42% { color: green; }
    57% { color: blue; }
    71% { color: indigo; }
    85% { color: violet; }
    100% { color: red; }
  }
  
  .rainbow-text {
    animation: rainbow 1.5s infinite;
  }
  .bitcoinDiv {
    text-align: center;
  }